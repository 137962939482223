import { Link } from "react-router-dom";
import Header from "./components/Header";
import PageTitle from "./components/PageTitle";
import { useEffect, useState } from "react";
import protectedAPI from "./config/protectedAPI";

function MyClans() {
  const [clans, setClans] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedClan, setSelectedClan] = useState(0);
  const [teams, setTeams] = useState([]);
  const getOwnClans = async () => {
    try {
      const response = await protectedAPI.get("clan/getOwnClans");
      setClans(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getClanMembers = async (id) => {
    try {
      const response = await protectedAPI.get("clan/getMembers/" + id);
      setMembers(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getClanTeams = async (id) => {
    try {
      const response = await protectedAPI.get("clan/getTeams/" + id);
      setTeams(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const updateClanMemberStatus = async (id, status) => {
    try {
      await protectedAPI.post("clan/updateMemberStatus", {
        id,
        status,
      });
      getClanMembers(selectedClan);
    } catch (error) {
      console.error(error);
    }
  };
  const updateClanTeamStatus = async (id, status) => {
    try {
      await protectedAPI.post("clan/updateTeamStatus", {
        id,
        status,
      });
      getClanTeams(selectedClan);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getOwnClans();
  }, []);
  return (
    <>
      <Header />
      <PageTitle title="YOUR OWN CLANS - YOU'RE MANAGING" />
      <div className="px-3">
        <div className="py-3">
          <div className="mt-3 mb-4">
            <h6 className="fw-light">{clans?.length} clans found.</h6>
          </div>
          <div>
            <table className="table table-striped table-hover align-middle">
              <thead>
                <tr>
                  <th className="text-secondary" scope="col"></th>
                  <th className="text-secondary" scope="col">
                    Name
                  </th>
                  <th className="text-secondary" scope="col">
                    Country
                  </th>
                  <th className="text-secondary" scope="col">
                    Tag
                  </th>
                  <th className="text-secondary" scope="col">
                    Status
                  </th>
                  <th className="text-secondary" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {clans.map((clan, index) => (
                  <tr key={index}>
                    <th style={{ width: "10%" }} scope="row">
                      <img
                        src={"https://api.lvgesports.com/" + clan.logo}
                        className="clan-row-img"
                        alt="..."
                      />
                    </th>
                    <td style={{ width: "20%" }}>{clan.name}</td>
                    <td style={{ width: "15%" }}>{clan.country}</td>
                    <td>{clan.tag}</td>
                    <td style={{ width: "10%" }}>
                      {clan.active ? (
                        <span className="text-success">Active</span>
                      ) : (
                        <span className="text-danger">Inactive</span>
                      )}
                    </td>
                    <td style={{ width: "20%" }}>
                      <Link
                        to={`/view/clan/${clan.id}`}
                        className="btn btn-sm btn-primary me-2"
                      >
                        <i className="bi bi-eye-fill"></i>
                      </Link>
                      <button
                        className="btn btn-sm btn-primary me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#clanMembersModal"
                        onClick={() => {
                          setSelectedClan(clan.id);
                          getClanMembers(clan.id);
                        }}
                      >
                        Members
                      </button>
                      <button
                        className="btn btn-sm btn-info"
                        data-bs-toggle="modal"
                        data-bs-target="#clanTeamsModal"
                        onClick={() => {
                          setSelectedClan(clan.id);
                          getClanTeams(clan.id);
                        }}
                      >
                        Teams
                      </button>
                      {/* <Link
                        to={`/clan/edit/${clan.id}`}
                        className="btn btn-sm btn-warning me-2"
                      >
                        <i className="bi bi-pen-fill"></i>
                      </Link>
                      <Link
                        to={`/clan/delete/${clan.id}`}
                        className="btn btn-sm btn-danger"
                      >
                        <i className="bi bi-trash-fill"></i>
                      </Link> */}
                    </td>
                  </tr>
                ))}
                {clans.length === 0 && (
                  <tr>
                    <td colSpan="4">No clans found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="clanMembersModal"
        tabindex="-1"
        aria-labelledby="clanMembersModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="clanMembersModalLabel">
                Manage Clan Members
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Username</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {members.map((member, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {member.user.firstname + " " + member.user.lastname}
                      </td>
                      <td>{member.user.email}</td>
                      <td>{member.user.username}</td>
                      <td>
                        {
                          <span
                            className={
                              member.status == 1
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {member.status == 1 ? "Active" : "Inactive"}
                          </span>
                        }
                      </td>
                      <td>
                        {
                          // update status
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              updateClanMemberStatus(
                                member.id,
                                member.status == 1 ? 0 : 1
                              );
                            }}
                          >
                            {member.status == 1 ? "Deactivate" : "Activate"}
                          </button>
                        }
                      </td>
                    </tr>
                  ))}
                  {members.length === 0 && (
                    <tr>
                      <td colSpan="4">No members found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="clanTeamsModal"
        tabindex="-1"
        aria-labelledby="clanTeamsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="clanTeamsModalLabel">
                Manage Clan Teams
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Country</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {teams.map((team, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{team.team.name}</td>
                      <td>{team.team.country}</td>
                      <td>
                        {
                          <span
                            className={
                              team.status == 1 ? "text-success" : "text-danger"
                            }
                          >
                            {team.status == 1 ? "Active" : "Inactive"}
                          </span>
                        }
                      </td>
                      <td>
                        {
                          // update status
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              updateClanTeamStatus(
                                team.id,
                                team.status == 1 ? 0 : 1
                              );
                            }}
                          >
                            {team.status == 1 ? "Deactivate" : "Activate"}
                          </button>
                        }
                      </td>
                    </tr>
                  ))}
                  {teams.length === 0 && (
                    <tr>
                      <td colSpan="4">No teams found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyClans;
