import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import publicAPI from "./config/publicAPI";
import { useNavigate } from "react-router-dom";

function Register() {
  const navigate = useNavigate();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const registration = async (e) => {
    e.preventDefault();
    if (firstname === "" || email === "" || password === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "All fields are required!",
      });
      return;
    }
    // passwoord must be at least 8 characters
    if (password.length < 8) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Password must be at least 8 characters!",
      });
      return;
    }
    const data = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password,
    };
    publicAPI
      .post("auth/register", data)
      .then((response) => {
        setFirstname("");
        setLastname("");
        setEmail("");
        setPassword("");
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        }).then((isConfirmed) => {
          if (isConfirmed) {
            navigate("/login");
          }
        });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        }
      });
  };
  useEffect(() => {
    // click the button to open the modal
    document.getElementById("registerModalButton").click();
  }, []);
  return (
    <section class="mt-0 pt-5" style={{ height: "100vh" }}>
      <button
        id="registerModalButton"
        className="btn d-none"
        data-bs-toggle="modal"
        data-bs-target="#registerModal"
      ></button>
      <div
        className="modal fade"
        id="registerModal"
        tabindex="-1"
        aria-labelledby="registerModalLabel"
        aria-hidden="true"
        // data-bs-backdrop="static"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ width: "400px" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <form id="register_form">
                <div className="d-flex flex-column">
                  <div class="text-center">
                    <img
                      src={require("./images/logo.png")}
                      alt=""
                      width={200}
                    />
                    <p>Registration</p>
                  </div>
                  <hr />
                  <div className="d-flex mb-3">
                    <div className="form-group me-2 w-50">
                      <label htmlFor="firstname" className="form-label">
                        Nickname
                      </label>
                      <input
                        type="text"
                        id="firstname"
                        className="form-control"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                      />
                    </div>
                    <div className="form-group w-50">
                      <label htmlFor="lastname" className="form-label">
                        Other Name
                      </label>
                      <input
                        type="text"
                        id="lastname"
                        className="form-control"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group my-3">
                    <button
                      className="btn btn-primary w-100"
                      onClick={registration}
                    >
                      Become a Member
                    </button>
                  </div>
                  <div className="form-group">
                    <Link to="/login" className="btn btn-default w-100">
                      Already have an account? Login
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Register;
