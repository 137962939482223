import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Clans from "./Clans";
import MyTeams from "./MyTeams";
import ViewClan from "./ViewClan";
import Dashboard from "./Dashboard";
import Register from "./Register";
import Login from "./Login";
import ViewTeam from "./ViewTeam";
import MyClans from "./MyClans";
import AdminTeams from "./admin/AdminTeams";
import AdminClans from "./admin/AdminClans";
import CreateTournament from "./admin/CreateTournament";
import AllTournaments from "./admin/AllTournaments";
import Users from "./admin/Users";
import ViewTournament from "./ViewTournament";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/clans" element={<Clans />} />
        <Route path="/my-teams" element={<MyTeams />} />
        <Route path="/my-clans" element={<MyClans />} />
        <Route path="/view/clan/:id" element={<ViewClan />} />
        <Route path="/view/team/:id" element={<ViewTeam />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/admin/teams" element={<AdminTeams />} />
        <Route path="/admin/clans" element={<AdminClans />} />
        <Route path="/admin/tournaments" element={<AllTournaments />} />
        <Route path="/admin/tournaments/create" element={<CreateTournament />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/view/tournament/:id" element={<ViewTournament />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
