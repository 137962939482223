import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import { useEffect, useState } from "react";
import protectedAPI from "../config/protectedAPI";
import AdminHeader from "../components/AdminHeader";
import Swal from "sweetalert2";

function AllTournaments() {
  const [upcomingTorunaments, setUpcomingTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState({});
  const navigate = useNavigate();
  const uploadBracket = async () => {
    const formData = new FormData();
    var bracketImage = document.getElementById("bracketImage").files[0];
    if (!bracketImage) {
      Swal.fire("Error", "Please select an image", "error");
      return;
    }
    formData.append("bracket", bracketImage);
    try {
      const response = await protectedAPI.post(
        "tournament/uploadBracket/" + selectedTournament.id,
        formData
      );
      console.log(response.data);
      getAllTournaments();
      Swal.fire("Success", "Bracket uploaded successfully", "success");
    } catch (error) {
      console.error(error);
      Swal.fire("Error", "Failed to upload bracket", "error");
    }
  };
  const uploadSchedule = async () => {
    const formData = new FormData();
    var scheduleImage = document.getElementById("scheduleImage").files[0];
    if (!scheduleImage) {
      Swal.fire("Error", "Please select an image", "error");
      return;
    }
    formData.append("schedule", scheduleImage);
    try {
      const response = await protectedAPI.post(
        "tournament/uploadSchedule/" + selectedTournament.id,
        formData
      );
      console.log(response.data);
      getAllTournaments();
      Swal.fire("Success", "Schedule uploaded successfully", "success");
    } catch (error) {
      console.error(error);
      Swal.fire("Error", "Failed to upload schedule", "error");
    }
  };
  const getAllTournaments = async () => {
    try {
      const response = await protectedAPI.get("tournament/all");
      setUpcomingTournaments(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAllTournaments();
  }, []);
  return (
    <>
      <AdminHeader />
      <PageTitle title="Tournaments" />
      <div className="px-3">
        <div className="py-3">
          <div className="d-flex w-100 mt-3 mb-4">
            <div>
              <h6 className="fw-light">
                {upcomingTorunaments.length} Tournaments found.
              </h6>
            </div>
            <div className="ms-auto">
              <Link
                className="btn btn-success mt-2"
                to="/admin/tournaments/create"
              >
                Create Tournament
              </Link>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            {upcomingTorunaments.map((tournament) => (
              <div
                className="card hover-card mx-2"
                style={{ width: "18rem" }}
                key={tournament.id}
              >
                <img
                  src={"https://api.lvgesports.com/" + tournament.coverPhoto}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h4
                    className="card-title fw-bold"
                    onClick={() => {
                      navigate("/view/tournament/" + tournament.id);
                    }}
                  >
                    {tournament.title}
                  </h4>
                  <hr />
                  <div className="row mb-3">
                    <div className="col-6">
                      <span className="fw-light">Country</span>
                    </div>
                    <div className="col-6">
                      <span className="fw-bold text-info">
                        {tournament.country}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6 d-flex flex-column">
                      <span className="fw-light">Organizer</span>
                      <span className="fw-bold text-info">
                        {tournament.organizer}
                      </span>
                    </div>
                    <div className="col-6 d-flex flex-column">
                      <span className="fw-light">Location</span>
                      <span className="fw-bold text-info">
                        {tournament.location}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6 d-flex flex-column">
                      <span className="fw-light">Start Date</span>
                      <span className="fw-bold text-info">
                        {
                          // tournament.startDate to be formatted as yyyy-mm-dd, currently it is like 2024-06-07T00:00:00.000Z
                          tournament.startDate &&
                            tournament.startDate.split("T")[0]
                        }
                      </span>
                    </div>
                    <div className="col-6 d-flex flex-column">
                      <span className="fw-light">End Date</span>
                      <span className="fw-bold text-info">
                        {
                          // tournament.endDate to be formatted as yyyy-mm-dd, currently it is like 2024-06-07T00:00:00.000Z
                          tournament.endDate && tournament.endDate.split("T")[0]
                        }
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="row mb-3">
                    <div className="col-6 d-flex flex-column">
                      <span className="fw-light">
                        0 Gamers already registered.
                      </span>
                    </div>
                    <div className="col-6 d-flex flex-column">
                      <span className="fw-light">{tournament.status}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex flex-column">
                      {!tournament.bracket && (
                        <button
                          className="btn btn-secondary mt-1"
                          data-bs-toggle="modal"
                          data-bs-target="#uploadBracketModal"
                          onClick={() => {
                            setSelectedTournament(tournament);
                          }}
                        >
                          Upload Bracket
                        </button>
                      )}
                      {!tournament.schedule && (
                        <button
                          className="btn btn-secondary mt-1"
                          data-bs-toggle="modal"
                          data-bs-target="#uploadScheduleModal"
                          onClick={() => {
                            setSelectedTournament(tournament);
                          }}
                        >
                          Upload Schedule
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {upcomingTorunaments.length === 0 && (
              <div className="text-center w-100">
                <h5>No tournaments found.</h5>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="uploadBracketModal"
        tabindex="-1"
        aria-labelledby="uploadBracketModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="uploadBracketModalLabel">
                UPLOAD BRACKET
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex flex-column">
                <div className="form-group mb-3">
                  <label htmlFor="bracketImage" className="form-label">
                    Choose Bracket Image
                  </label>
                  <input
                    type="file"
                    name="bracketImage"
                    id="bracketImage"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={uploadBracket}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="uploadScheduleModal"
        tabindex="-1"
        aria-labelledby="uploadScheduleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="uploadScheduleModalLabel">
                UPLOAD SCHEDULE
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex flex-column">
                <div className="form-group mb-3">
                  <label htmlFor="scheduleImage" className="form-label">
                    Choose Schedule Image
                  </label>
                  <input
                    type="file"
                    name="scheduleImage"
                    id="scheduleImage"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={uploadSchedule}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllTournaments;
