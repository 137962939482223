import { Link } from "react-router-dom";
import Header from "./components/Header";
import PageTitle from "./components/PageTitle";
import protectedAPI from "./config/protectedAPI";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

function Dashboard() {
  const [user, setUser] = useState({});
  const [teams, setTeams] = useState([]);
  const [clans, setClans] = useState([]);
  const [countries, setContries] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [dob, setDob] = useState("");
  const [mobile, setMobile] = useState("");
  const [nationality, setNationality] = useState("");
  const [teamJoinRequests, setTeamJoinRequests] = useState([]);
  // get user from local storage
  const getUser = async () => {
    try {
      const response = await protectedAPI.get("user/getUserById");
      setUser(response.data);
      setFirstname(response.data.firstname);
      setLastname(response.data.lastname);
      setEmail(response.data.email);
      setUsername(response.data.username);
      setDob(response.data.dob);
      setMobile(response.data.mobile);
      setNationality(response.data.nationality);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getAllCountries = async () => {
    protectedAPI
      .get("utility/getCountryList")
      .then((response) => {
        setContries(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTeams = async () => {
    try {
      const response = await protectedAPI.get("team/getTeams");
      setTeams(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getClans = async () => {
    try {
      const response = await protectedAPI.get("clan/getClans");
      setClans(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getTeamJoinRequests = async () => {
    try {
      const response = await protectedAPI.get("user/getTeamJoinRequests");
      setTeamJoinRequests(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const acceptTeamJoinRequest = async (teamId) => {
    try {
      const response = await protectedAPI.post("team/joinTeam", {
        teamId: teamId,
      });
      getTeamJoinRequests();
      getTeams();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const rejectTeamJoinRequest = async (teamId) => {
    try {
      const response = await protectedAPI.post("team/leaveTeam", {
        teamId: teamId,
      });
      getTeamJoinRequests();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const updateUserDetails = async () => {
    try {
      const data = new FormData();
      data.append("firstname", firstname);
      data.append("lastname", lastname);
      data.append("username", username);
      data.append("dob", dob);
      data.append("mobile", mobile);
      data.append("nationality", nationality);
      var logo = document.getElementById("logo3").files[0];
      // if logo is selected
      if (logo) {
        data.append("logo", logo);
      }
      protectedAPI
        .post("user/updateUserDetails", data)
        .then((response) => {
          getUser();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getUser();
    getTeams();
    getClans();
    getAllCountries();
    getTeamJoinRequests();
  }, []);
  return (
    <>
      <Header />
      <PageTitle
        title={"Welcome back, " + user.firstname + " " + user.lastname}
      />
      <div className="px-3">
        <div className="py-3">
          <ul className="nav nav-underline" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected="true"
              >
                Overview
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="request-tab"
                data-bs-toggle="tab"
                data-bs-target="#request-tab-pane"
                type="button"
                role="tab"
                aria-controls="request-tab-pane"
                aria-selected="true"
              >
                Team Join Request
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile-tab-pane"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected="false"
              >
                My Account
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active py-4 px-0"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="d-flex">
                    <div className="me-auto">
                      <h4 className="fw-bold ps-3 border-5 border-info border-start">
                        Teams You Are In
                      </h4>
                    </div>
                    <div>
                      <button
                        className="btn btn-success me-5"
                        data-bs-toggle="modal"
                        data-bs-target="#createTeamModal"
                      >
                        CREATE TEAM
                      </button>
                    </div>
                  </div>
                  <table className="table align-middle mt-3">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Name</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {teams.map((team, index) => (
                        <tr key={index}>
                          <td style={{ width: "15%" }}>
                            <img
                              src={"https://api.lvgesports.com/" + team.logo}
                              className="clan-row-img"
                              alt="..."
                            />
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <strong>{team.name}</strong>
                              {team.clan ? (
                                <small className="text-info">
                                  {team.clan.name}
                                </small>
                              ) : (
                                <small className="text-secondary">
                                  Not joined to a clan yet.
                                </small>
                              )}
                            </div>
                          </td>
                          <td style={{ width: "15%" }}>
                            <Link
                              to={`/view/team/${team.id}`}
                              className="btn btn-primary"
                            >
                              <i className="bi bi-eye-fill"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                      {teams.length === 0 && (
                        <tr>
                          <td colSpan="3" className="text-center">
                            No teams found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6">
                  <div className="d-flex">
                    <div className="me-auto">
                      <h4 className="fw-bold ps-3 border-5 border-info border-start">
                        Your Organizations
                      </h4>
                    </div>
                    <div>
                      <button
                        className="btn btn-success me-5"
                        data-bs-toggle="modal"
                        data-bs-target="#createOrganizationModal"
                      >
                        CREATE ORGANIZATION
                      </button>
                    </div>
                  </div>
                  <table className="table align-middle mt-3">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Name</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {clans.map((clan, index) => (
                        <tr key={index}>
                          <td style={{ width: "15%" }}>
                            <img
                              src={
                                "https://api.lvgesports.com/" + clan.clan.logo
                              }
                              className="clan-row-img"
                              alt="..."
                            />
                          </td>
                          <td>{clan.clan.name}</td>
                          <td style={{ width: "15%" }}>
                            <Link
                              to={`/view/clan/${clan.clan.id}`}
                              className="btn btn-primary"
                            >
                              <i className="bi bi-eye-fill"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                      {clans.length === 0 && (
                        <tr>
                          <td colSpan="3" className="text-center">
                            No clans found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade py-4 px-0"
              id="request-tab-pane"
              role="tabpanel"
              aria-labelledby="request-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex">
                    <div className="me-auto">
                      <h4 className="fw-bold ps-3 border-5 border-info border-start">
                        Pending Requests
                      </h4>
                    </div>
                  </div>
                  <table className="table align-middle mt-3">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Team Name</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {teamJoinRequests.map((team, index) => (
                        <tr key={index}>
                          <td style={{ width: "15%" }}>{index + 1}</td>
                          <td>
                            <div className="d-flex flex-column">
                              <strong>{team.team.name}</strong>
                            </div>
                          </td>
                          <td style={{ width: "15%" }}>
                            <button
                              className="btn btn-success me-1"
                              onClick={() => acceptTeamJoinRequest(team.teamId)}
                            >
                              Accept
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => rejectTeamJoinRequest(team.teamId)}
                            >
                              Reject
                            </button>
                          </td>
                        </tr>
                      ))}
                      {teamJoinRequests.length === 0 && (
                        <tr>
                          <td colSpan="3" className="text-center">
                            No requests found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade py-3 px-0"
              id="profile-tab-pane"
              role="tabpanel"
              aria-labelledby="profile-tab"
              tabindex="0"
            >
              <div className="mt-3 mb-4">
                <div className="d-flex">
                  <div className="d-flex align-items-center me-auto">
                    <img
                      src={
                        user.photo
                          ? "https://api.lvgesports.com/" + user.photo
                          : require("./images/user.png")
                      }
                      className="clan-row-img me-4"
                      style={{ width: "100px", height: "100px" }}
                      alt="..."
                    />
                    <h4 className="fw-light">Your Details</h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-success"
                      data-bs-toggle="modal"
                      data-bs-target="#updateAccountModal"
                    >
                      Update Account Details
                    </button>
                  </div>
                </div>
                <hr />
              </div>
              <div>
                <div className="row">
                  <div className="col-md-6 d-flex flex-column">
                    <h6 className="text-info">Full Name</h6>
                    <h3>
                      <strong>{user.firstname + " " + user.lastname}</strong>
                    </h3>
                  </div>
                  <div className="col-md-6 d-flex flex-column">
                    <h6 className="text-info">Email</h6>
                    <h3>
                      <strong>{user.email}</strong>
                    </h3>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6 d-flex flex-column">
                    <h6 className="text-info">Username</h6>
                    <h3>
                      <strong>{user.username ? user.username : "---"}</strong>
                    </h3>
                  </div>
                  <div className="col-md-6 d-flex flex-column">
                    <h6 className="text-info">Birth Day</h6>
                    <h3>
                      <strong>{user.dob ? user.dob : "---"}</strong>
                    </h3>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6 d-flex flex-column">
                    <h6 className="text-info">Mobile Number</h6>
                    <h3>
                      <strong>{user.mobile ? user.mobile : "---"}</strong>
                    </h3>
                  </div>
                  <div className="col-md-6 d-flex flex-column">
                    <h6 className="text-info">Nationality</h6>
                    <h3>
                      <strong>
                        {user.nationality ? user.nationality : "---"}
                      </strong>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="updateAccountModal"
          tabindex="-1"
          aria-labelledby="updateAccountModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="updateAccountModalLabel">
                  Update Your Details
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-column">
                  <div className="form-group mb-3">
                    <label htmlFor="firstname" className="form-label">
                      Firstname
                    </label>
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      className="form-control"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="lastname" className="form-label">
                      Lastname
                    </label>
                    <input
                      type="text"
                      name="lastname"
                      id="lastname"
                      className="form-control"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="username" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      className="form-control"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="dob" className="form-label">
                      Dob
                    </label>
                    <input
                      type="date"
                      name="dob"
                      id="dob"
                      className="form-control"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="mobile" className="form-label">
                      Mobile
                    </label>
                    <input
                      type="text"
                      name="mobile"
                      id="mobile"
                      className="form-control"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="nationality" className="form-label">
                      Nationality
                    </label>
                    <select
                      name="nationality"
                      id="nationality"
                      className="form-control"
                      value={nationality}
                      onChange={(e) => setNationality(e.target.value)}
                    >
                      <option value=""></option>
                      {countries.map((country) => (
                        <option key={country.iso} value={country.nicename}>
                          {country.nicename}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="logo3" className="form-label">
                      Profile Photo
                    </label>
                    <input
                      type="file"
                      name="logo3"
                      id="logo3"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={updateUserDetails}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
