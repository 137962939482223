import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import protectedAPI from "./config/protectedAPI";

function CreateTeam() {
  const [countries, setContries] = useState([]);
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [tag, setTag] = useState("");
  const [members, setMembers] = useState([]);
  const getAllCountries = async () => {
    protectedAPI
      .get("utility/getCountryList")
      .then((response) => {
        setContries(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const createTeam = async () => {
    if (name === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Name is required!",
      });
      return;
    }
    const data = new FormData();
    data.append("name", name);
    data.append("country", country);
    data.append("tag", tag);
    var logo = document.getElementById("logo").files[0];
    data.append("logo", logo);
    // get all members in the members array and convert it to a string separated by commas
    var processed_members = members.join(",");
    data.append("members", processed_members);
    protectedAPI
      .post("team/create", data)
      .then((response) => {
        setName("");
        setCountry("");
        setTag("");
        setMembers("");
        document.getElementById("logo").value = "";
        document.getElementById("members").value = "";
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };
  useEffect(() => {
    getAllCountries();
  }, []);
  return (
    <>
      <div
        className="modal fade"
        id="createTeamModal"
        tabindex="-1"
        aria-labelledby="createTeamModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="createTeamModalLabel">
                CREATE A NEW TEAM
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex flex-column">
                <div className="form-group mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="country" className="form-label">
                    Country
                  </label>
                  <select
                    name="country"
                    id="country"
                    className="form-control"
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option value="Sri Lanka"></option>
                    {countries.map((country) => (
                      <option key={country.iso} value={country.nicename}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="tag" className="form-label">
                    Tag [Optional]
                  </label>
                  <input
                    type="text"
                    name="tag"
                    id="tag"
                    className="form-control"
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="logo" className="form-label">
                    Logo [Optional]
                  </label>
                  <input
                    type="file"
                    name="logo"
                    id="logo"
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="t_member" className="form-label">
                    Enter Member Email
                  </label>
                  <input
                    type="text"
                    name="t_member"
                    id="t_member"
                    className="form-control"
                  />
                  <button className="btn btn-secondary mt-2" onClick={()=>{
                    // Add member to the members array
                    var member = document.getElementById("t_member").value;
                    setMembers([...members, member]);
                  }}>Add Member</button>
                </div>
                <div className="d-flex flex-column">
                  <strong>Members List</strong>
                  <small className="text-secondary">A email with a request to join team will be send to these players.</small>
                  <table className="table table-striped align-middle mt-3">
                    <thead>
                      <th>Email</th>
                      <th>Action</th>
                    </thead>
                    <tbody>
                      {members && members.map((member, index) => (
                        <tr key={index}>
                          <td>{member}</td>
                          <td>
                            <button className="btn btn-danger" onClick={()=>{
                              // Remove member from the members array
                              var newMembers = members.filter((m) => m !== member);
                              setMembers(newMembers);
                            }}>Remove</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary" onClick={createTeam}>
                Create Team
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateTeam;
