import { Link } from "react-router-dom";
import Header from "./components/Header";
import PageTitle from "./components/PageTitle";
import { useEffect, useState } from "react";
import protectedAPI from "./config/protectedAPI";
import Swal from "sweetalert2";
import publicAPI from "./config/publicAPI";

function MyTeams() {
  const [teams, setTeams] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(0);
  const [selectedClan, setSelectedClan] = useState(0);
  const [clans, setClans] = useState([]);

  const getClans = async () => {
    try {
      const response = await publicAPI.get("auth/clans");
      setClans(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getOwnTeams = async () => {
    try {
      const response = await protectedAPI.get("team/getOwnTeams");
      setTeams(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const addMembers = async () => {
    if (selectedTeam == 0) alert("Please select a team.");
    const data = new FormData();
    // get all members in the members array and convert it to a string separated by commas
    var processed_members = members.join(",");
    data.append("members", processed_members);
    data.append("teamId", selectedTeam);
    protectedAPI
      .post("team/add/members", data)
      .then((response) => {
        setMembers([]);
        console.log(response);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };
  const joinClan = async () => {
    if (selectedTeam == 0) alert("Please select a team.");
    if (selectedClan == 0) alert("Please select a clan.");
    const data = new FormData();
    data.append("teamId", selectedTeam);
    data.append("clanId", selectedClan);
    protectedAPI
      .post("clan/joinClanAsATeam", data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
        getOwnTeams();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };
  useEffect(() => {
    getOwnTeams();
    getClans();
  }, []);
  return (
    <>
      <Header />
      <PageTitle title="YOUR OWN TEAMS - YOU'RE MANAGING" />
      <div className="px-3">
        <div className="py-3">
          <div className="mt-3 mb-4">
            <h6 className="fw-light">{teams?.length} Teams found.</h6>
          </div>
          <div>
            <table className="table table-striped table-hover align-middle">
              <thead>
                <tr>
                  <th className="text-secondary" scope="col"></th>
                  <th className="text-secondary" scope="col">
                    Name
                  </th>
                  <th className="text-secondary" scope="col">
                    Country
                  </th>
                  <th className="text-secondary" scope="col">
                    Tag
                  </th>
                  <th className="text-secondary" scope="col">
                    Status
                  </th>
                  <th className="text-secondary" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {teams.map((team, index) => (
                  <tr key={index}>
                    <th style={{ width: "10%" }} scope="row">
                      <img
                        src={"https://api.lvgesports.com/" + team.logo}
                        className="clan-row-img"
                        alt="..."
                      />
                    </th>
                    <td style={{ width: "20%" }}>
                      <div className="d-flex flex-column">
                        <strong>{team.name}</strong>
                        {team.clan != null ? (
                          <small className="text-info">{team.clan.name}</small>
                        ) : (
                          <small className="text-secondary">
                            Not joined to a clan yet.
                          </small>
                        )}
                      </div>
                    </td>
                    <td style={{ width: "15%" }}>{team.country}</td>
                    <td>{team.tag}</td>
                    <td style={{ width: "10%" }}>
                      {team.active ? (
                        <span className="text-success">Active</span>
                      ) : (
                        <span className="text-danger">Inactive</span>
                      )}
                    </td>
                    <td style={{ width: "15%" }}>
                      <Link
                        to={`/view/team/${team.id}`}
                        className="btn btn-sm btn-primary me-2"
                      >
                        <i className="bi bi-eye-fill"></i>
                      </Link>
                      <button
                        className="btn btn-sm btn-warning me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#addTeamMembersModal"
                        onClick={() => setSelectedTeam(team.id)}
                      >
                        <i className="bi bi-person-fill"></i>
                      </button>
                      <button
                        className="btn btn-sm btn-info me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#joinClanModal"
                        onClick={() => setSelectedTeam(team.id)}
                      >
                        <i className="bi bi-diagram-2-fill"></i>
                      </button>
                      {/* <Link
                        to={`/team/delete/${team.id}`}
                        className="btn btn-sm btn-danger"
                      >
                        <i className="bi bi-trash-fill"></i>
                      </Link> */}
                    </td>
                  </tr>
                ))}
                {teams.length === 0 && (
                  <tr>
                    <td colSpan="4">No teams found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addTeamMembersModal"
        tabindex="-1"
        aria-labelledby="addTeamMembersModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="addTeamMembersModalLabel">
                Add New Team Members
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex flex-column">
                <div className="form-group mb-3">
                  <label htmlFor="t_memberx" className="form-label">
                    Enter Member Email
                  </label>
                  <input
                    type="text"
                    name="t_memberx"
                    id="t_memberx"
                    className="form-control"
                  />
                  <button
                    className="btn btn-secondary mt-2"
                    onClick={() => {
                      // Add member to the members array
                      var memberx = document.getElementById("t_memberx").value;
                      setMembers([...members, memberx]);
                    }}
                  >
                    Add Member
                  </button>
                </div>
                <div className="d-flex flex-column">
                  <strong>Members List</strong>
                  <small className="text-secondary">
                    A email with a request to join team will be send to these
                    players.
                  </small>
                  <table className="table table-striped align-middle mt-3">
                    <thead>
                      <th>Email</th>
                      <th>Action</th>
                    </thead>
                    <tbody>
                      {members &&
                        members.map((member, index) => (
                          <tr key={index}>
                            <td>{member}</td>
                            <td>
                              <button
                                className="btn btn-danger"
                                onClick={() => {
                                  // Remove member from the members array
                                  var newMembers = members.filter(
                                    (m) => m !== member
                                  );
                                  setMembers(newMembers);
                                }}
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={addMembers}
              >
                Add Members
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="joinClanModal"
        tabindex="-1"
        aria-labelledby="joinClanModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="joinClanModalLabel">
                Join a Clan
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex flex-column">
                <div className="form-group mb-3">
                  <label htmlFor="clan" className="form-label">
                    join A Clan
                  </label>
                  <select
                    name="clan"
                    id="clan"
                    className="form-control"
                    onChange={(e) => setSelectedClan(e.target.value)}
                  >
                    <option value="">Select</option>
                    {clans.map((clan, index) => (
                      <option key={index} value={clan.id}>
                        {clan.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={joinClan}
              >
                Join
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyTeams;
