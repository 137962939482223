import { Link } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import { useEffect, useState } from "react";
import protectedAPI from "../config/protectedAPI";
import AdminHeader from "../components/AdminHeader";
import Swal from "sweetalert2";

function CreateTournament() {
  const [countries, setContries] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [game, setGame] = useState("");
  const [country, setCountry] = useState("");
  const [location, setLocation] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [registrationClosingDate, setRegistrationClosingDate] = useState("");
  const [maxPlayersPerTeam, setMaxPlayersPerTeam] = useState("");
  const [maxTeamsPerClan, setMaxTeamsPerClan] = useState("");

  const getAllCountries = async () => {
    protectedAPI
      .get("utility/getCountryList")
      .then((response) => {
        setContries(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createTournament = async () => {
    // get all the values from the form and append to a new formData
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("game", game);
    formData.append("country", country);
    formData.append("location", location);
    formData.append("organizer", organizer);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("status", currentStatus);
    formData.append("registrationClosingDate", registrationClosingDate);
    formData.append("maxPlayersPerTeam", maxPlayersPerTeam);
    formData.append("maxTeamsPerClan", maxTeamsPerClan);
    var coverPhoto = document.getElementById("coverPhoto").files[0];
    if (coverPhoto) {
      formData.append("coverPhoto", coverPhoto);
    }
    await protectedAPI
      .post("tournament/create", formData)
      .then((response) => {
        Swal.fire("Success", "Tournament created successfully", "success");
        setTitle("");
        setDescription("");
        setGame("");
        setCountry("");
        setLocation("");
        setOrganizer("");
        setStartDate("");
        setEndDate("");
        setCurrentStatus("");
        setRegistrationClosingDate("");
        setMaxPlayersPerTeam("");
        setMaxTeamsPerClan("");
        document.getElementById("coverPhoto").value = "";
      })
      .catch((error) => {
        Swal.fire("Error", error.response.data.message, "error");
      });
  };

  useEffect(() => {
    getAllCountries();
  }, []);
  return (
    <>
      <AdminHeader />
      <PageTitle title="CREATE A NEW TOURNAMENT" />
      <div className="px-3">
        <div className="py-3">
          <div className="col-md-6 offset-md-3 mt-3 mb-4">
            <div className="form-group mb-3">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                name="title"
                id="title"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <textarea
                name="description"
                id="description"
                className="form-control"
                value = {description}
                onChange = {(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="game" className="form-label">
                Game
              </label>
              <input
                type="text"
                name="game"
                id="game"
                className="form-control"
                value={game}
                onChange={(e) => setGame(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="country" className="form-label">
                Country
              </label>
              <select
                name="country"
                id="country"
                className="form-control"
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="Sri Lanka"></option>
                {countries.map((country) => (
                  <option key={country.iso} value={country.nicename}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="location" className="form-label">
                Location
              </label>
              <input
                type="text"
                name="location"
                id="location"
                className="form-control"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="organizer" className="form-label">
                Organizer
              </label>
              <input
                type="text"
                name="organizer"
                id="organizer"
                className="form-control"
                value={organizer}
                onChange={(e) => setOrganizer(e.target.value)}
              />
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="startDate" className="form-label">
                    Start Date
                  </label>
                  <input
                    type="date"
                    name="startDate"
                    id="startDate"
                    className="form-control"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="endDate" className="form-label">
                    End Date
                  </label>
                  <input
                    type="date"
                    name="endDate"
                    id="endDate"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="currentStatus" className="form-label">
                Current Status
              </label>
              <input
                type="text"
                name="currentStatus"
                id="currentStatus"
                className="form-control"
                value={currentStatus}
                onChange={(e) => setCurrentStatus(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="registrationClosingDate" className="form-label">
                Registrations Closing Date
              </label>
              <input
                type="date"
                name="registrationClosingDate"
                id="registrationClosingDate"
                className="form-control"
                value={registrationClosingDate}
                onChange={(e) => setRegistrationClosingDate(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="maxPlayersPerTeam" className="form-label">
                Max Players Per Team
              </label>
              <input
                type="text"
                name="maxPlayersPerTeam"
                id="maxPlayersPerTeam"
                className="form-control"
                value={maxPlayersPerTeam}
                onChange={(e) => setMaxPlayersPerTeam(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="maxTeamsPerClan" className="form-label">
                Max Teams Per Clan
              </label>
              <input
                type="text"
                name="maxTeamsPerClan"
                id="maxTeamsPerClan"
                className="form-control"
                value={maxTeamsPerClan}
                onChange={(e) => setMaxTeamsPerClan(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="coverPhoto" className="form-label">
                Cover Image
              </label>
              <input
                type="file"
                name="coverPhoto"
                id="coverPhoto"
                className="form-control"
              />
            </div>
            <div className="mt-5">
              <button className="btn btn-primary" onClick={createTournament}>
                CREATE TOURNAMENT
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateTournament;
