import { Link, useParams } from "react-router-dom";
import Header from "./components/Header";
import PageTitle from "./components/PageTitle";
import protectedAPI from "./config/protectedAPI";
import { useEffect, useState } from "react";

function ViewTeam() {
  const [team, setTeam] = useState("");
  const { id } = useParams();
  const getTeam = async () => {
    try {
      const response = await protectedAPI.get("team/getTeamById/" + id);
      setTeam(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getTeam();
  }, []);
  return (
    <>
      <Header />
      <PageTitle
        title={team.name}
        bg={"https://api.lvgesports.com/" + team.logo}
      />
      <div className="px-3">
        <div className="py-3">
          <ul className="nav nav-underline" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected="true"
              >
                Overview
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile-tab-pane"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected="false"
              >
                Members
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active py-4 px-0"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-md-3" style={{ minHeight: "15rem" }}>
                  <div className="d-flex flex-column justify-content-center align-items-center bg-secondary-subtle h-100">
                    <h1 className="fw-bold" style={{ fontSize: "6rem" }}>
                      {team.members?.length}
                    </h1>
                    <h4>Members</h4>
                  </div>
                </div>
                <div className="col-md-3" style={{ minHeight: "15rem" }}>
                  <div className="d-flex flex-column justify-content-center align-items-center bg-secondary-subtle h-100">
                    <h1 className="fw-bold" style={{ fontSize: "6rem" }}>
                      0
                    </h1>
                    <h4>Tournaments Played</h4>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-8 d-flex flex-column">
                  <div className="border-5 border-info border-start p-3 mt-4 align-items-center">
                    <h4 className="fw-bold">Description</h4>
                    <p>
                      {team.description ? team.description : "No description"}
                    </p>
                  </div>
                  <div className="border-5 border-info border-start p-3 mt-4 align-items-center">
                    <h4 className="fw-bold">Achievements</h4>
                    <p>
                      The best clan in the country. We have won 13 tournaments
                      so far.
                    </p>
                  </div>
                  <div className="border-5 border-info border-start p-3 mt-4 align-items-center">
                    <h4 className="fw-bold">Country Reference</h4>
                    <p>{team.country}</p>
                  </div>
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <div className="border-5 border-info border-start p-3 mt-4 align-items-center">
                    <h4 className="fw-bold">Information</h4>
                    <p>
                      <i className="bi bi-globe me-3"></i>{" "}
                      <a href="#">www.lvg.com</a>
                    </p>
                    <p>
                      <i className="bi bi-twitter me-3"></i>{" "}
                      <a href="#">@lvg_official</a>
                    </p>
                    <p>
                      <i className="bi bi-facebook me-3"></i>{" "}
                      <a href="#">/lvg_official</a>
                    </p>
                    <p>
                      <i className="bi bi-instagram me-3"></i>{" "}
                      <a href="#">@lvg_official</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade py-3 px-0"
              id="profile-tab-pane"
              role="tabpanel"
              aria-labelledby="profile-tab"
              tabindex="0"
            >
              <div className="mt-3 mb-4">
                <h6 className="fw-light">
                  {team.members?.length} members found.
                </h6>
              </div>
              <div>
                <table className="table table-striped table-hover align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="text-secondary" scope="col">
                        Name
                      </th>
                      <th className="text-secondary" scope="col">
                        Username
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {team.members?.map((member, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>
                          {member.user.firstname + " " + member.user.lastname}
                        </td>
                        <td>
                          <div className="d-flex flex-column">
                            <strong>{member.user.email}</strong>
                            <span>{member.user.username}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {team.members?.length === 0 && (
                      <tr>
                        <td colSpan="2" className="text-center">
                          No members found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewTeam;
