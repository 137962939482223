import { Link, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import PageTitle from "./components/PageTitle";
import { useEffect, useState } from "react";
import publicAPI from "./config/publicAPI";
import Swal from "sweetalert2";
import protectedAPI from "./config/protectedAPI";

function Clans() {
  const [clans, setClans] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const getClans = async () => {
    try {
      const response = await publicAPI.get("auth/clans");
      var allClans = response.data;
      // get clans with active status is 1
      var activeClans = allClans.filter((clan) => clan.active == 1);
      setClans(activeClans);
    } catch (error) {
      console.error(error);
    }
  };
  const joinClan = async (id) => {
    if (!isLoggedIn) return;
    const data = new FormData();
    data.append("clanId", id);
    protectedAPI
      .post("clan/joinClanAsAMember", data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };
  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))?.role == 2001
    ) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    getClans();
  }, []);
  return (
    <>
      <Header />
      <PageTitle title="ORGANIZATIONS & CLANS" />
      <div className="px-3">
        <div className="py-3">
          <div className="mt-3 mb-4">
            <h6 className="fw-light">{clans?.length} clans found.</h6>
          </div>
          <div>
            <table className="table table-striped table-hover align-middle">
              <thead>
                <tr>
                  <th className="text-secondary" scope="col"></th>
                  <th className="text-secondary" scope="col">
                    Name
                  </th>
                  <th className="text-secondary" scope="col">
                    Members
                  </th>
                  <th className="text-secondary" scope="col">
                    Teams
                  </th>
                  <th className="text-secondary" scope="col">
                    Country
                  </th>
                  <th className="text-secondary" scope="col">
                    Description
                  </th>
                  <th className="text-secondary" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {clans.map((clan, index) => (
                  <tr key={index}>
                    <th scope="row">
                      <img
                        src={"https://api.lvgesports.com/" + clan.logo}
                        className="clan-row-img"
                        alt="..."
                      />
                    </th>
                    <td>{clan.name}</td>
                    <td>{clan.memberCount}</td>
                    <td>{clan.teamCount}</td>
                    <td>{clan.country}</td>
                    <td>{clan.tag}</td>
                    <td style={{ width: "20%" }}>
                      <Link
                        to={`/view/clan/${clan.id}`}
                        className="btn btn-primary me-1"
                      >
                        <i className="bi bi-eye-fill me-2"></i>
                        View Clan
                      </Link>
                      {isLoggedIn ? (
                        <>
                          <button
                            className="btn btn-success"
                            onClick={() => {
                              joinClan(clan.id);
                            }}
                          >
                            JOIN CLAN
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-success"
                            onClick={() => {
                              navigate("/login");
                            }}
                          >
                            JOIN CLAN
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Clans;
