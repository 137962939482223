import { Link } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import { useEffect, useState } from "react";
import protectedAPI from "../config/protectedAPI";
import AdminHeader from "../components/AdminHeader";

function AdminTeams() {
  const [teams, setTeams] = useState([]);
  const getAllTeams = async () => {
    try {
      const response = await protectedAPI.get("team/getAllTeams");
      setTeams(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const updateTeamStatus = async (id, status) => {
    try {
      await protectedAPI.post("team/updateTeamStatus/" + id, {
        active: status,
      });
      getAllTeams();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAllTeams();
  }, []);
  return (
    <>
      <AdminHeader />
      <PageTitle title="TEAMS MANAGEMENT" />
      <div className="px-3">
        <div className="py-3">
          <div className="mt-3 mb-4">
            <h6 className="fw-light">{teams?.length} Teams found.</h6>
          </div>
          <div>
            <table className="table table-striped table-hover align-middle">
              <thead>
                <tr>
                  <th className="text-secondary" scope="col"></th>
                  <th className="text-secondary" scope="col">
                    Name
                  </th>
                  <th className="text-secondary" scope="col">
                    Country
                  </th>
                  <th className="text-secondary" scope="col">
                    Members
                  </th>
                  <th className="text-secondary" scope="col">
                    Tag
                  </th>
                  <th className="text-secondary" scope="col">
                    Status
                  </th>
                  <th className="text-secondary" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {teams.map((team, index) => (
                  <tr key={index}>
                    <th style={{ width: "10%" }} scope="row">
                      <img
                        src={"https://api.lvgesports.com/" + team.logo}
                        className="clan-row-img"
                        alt="..."
                      />
                    </th>
                    <td style={{ width: "20%" }}>{team.name}</td>
                    <td style={{ width: "15%" }}>{team.country}</td>
                    <td style={{ width: "15%" }}>{team.memberCount}</td>
                    <td>{team.tag}</td>
                    <td style={{ width: "10%" }}>
                      {team.active == 1 ? (
                        <span className="text-success">Active</span>
                      ) : (
                        <span className="text-danger">Inactive</span>
                      )}
                    </td>
                    <td style={{ width: "10%" }}>
                      <button
                        className={
                          team.active == 1
                            ? "btn btn-danger"
                            : "btn btn-success"
                        }
                        onClick={() =>
                          updateTeamStatus(team.id, team.active == 1 ? 0 : 1)
                        }
                      >
                        {team.active == 1 ? "Deactivate" : "Activate"}
                      </button>
                    </td>
                  </tr>
                ))}
                {teams.length === 0 && (
                  <tr>
                    <td colSpan="4">No teams found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminTeams;
