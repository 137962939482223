import { Link } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import { useEffect, useState } from "react";
import protectedAPI from "../config/protectedAPI";
import AdminHeader from "../components/AdminHeader";

function AdminClans() {
  const [clans, setClans] = useState([]);
  const getAllClans = async () => {
    try {
      const response = await protectedAPI.get("clan/getAllClans");
      setClans(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const updateClanStatus = async (id, status) => {
    try {
      await protectedAPI.post("clan/updateClanStatus/" + id, {
        active: status,
      });
      getAllClans();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAllClans();
  }, []);
  return (
    <>
      <AdminHeader />
      <PageTitle title="CLANS MANAGEMENT" />
      <div className="px-3">
        <div className="py-3">
          <div className="mt-3 mb-4">
            <h6 className="fw-light">{clans?.length} Clans found.</h6>
          </div>
          <div>
            <table className="table table-striped table-hover align-middle">
              <thead>
                <tr>
                  <th className="text-secondary" scope="col"></th>
                  <th className="text-secondary" scope="col">
                    Name
                  </th>
                  <th className="text-secondary" scope="col">
                    Country
                  </th>
                  <th className="text-secondary" scope="col">
                    Members
                  </th>
                  <th className="text-secondary" scope="col">
                    Teams
                  </th>
                  <th className="text-secondary" scope="col">
                    Tag
                  </th>
                  <th className="text-secondary" scope="col">
                    Status
                  </th>
                  <th className="text-secondary" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {clans.map((clan, index) => (
                  <tr key={index}>
                    <th style={{ width: "10%" }} scope="row">
                      <img
                        src={"https://api.lvgesports.com/" + clan.logo}
                        className="clan-row-img"
                        alt="..."
                      />
                    </th>
                    <td style={{ width: "20%" }}>{clan.name}</td>
                    <td style={{ width: "15%" }}>{clan.country}</td>
                    <td style={{ width: "15%" }}>{clan.memberCount}</td>
                    <td style={{ width: "15%" }}>{clan.clanCount}</td>
                    <td>{clan.tag}</td>
                    <td style={{ width: "10%" }}>
                      {clan.active == 1 ? (
                        <span className="text-success">Active</span>
                      ) : (
                        <span className="text-danger">Inactive</span>
                      )}
                    </td>
                    <td style={{ width: "10%" }}>
                      <button
                        className={
                          clan.active == 1
                            ? "btn btn-danger"
                            : "btn btn-success"
                        }
                        onClick={() =>
                          updateClanStatus(clan.id, clan.active == 1 ? 0 : 1)
                        }
                      >
                        {clan.active == 1 ? "Deactivate" : "Activate"}
                      </button>
                    </td>
                  </tr>
                ))}
                {clans.length === 0 && (
                  <tr>
                    <td colSpan="4">No clans found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminClans;
