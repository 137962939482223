import React from "react";

function PageTitle(props) {
  return (
    <div
      className={props.bg ? "py-5 px-3" : "py-5 px-3 dark-bg"}
      style={{ backgroundImage: props.bg ? "url(" + props.bg + ")" : "" }}
    >
      <div className="py-5">
        <h1 className="fw-bold py-5">{props.title}</h1>
        <div className="pb-5"></div>
        <div className="pb-5"></div>
        <div className="pb-5"></div>
        <div className="pb-5"></div>
      </div>
    </div>
  );
}

export default PageTitle;
