import { Link, useNavigate } from "react-router-dom";
import PageTitle from "./components/PageTitle";
import { useEffect, useState } from "react";
import Header from "./components/Header";
import publicAPI from "./config/publicAPI";

function App() {
  const navigate = useNavigate();
  const [upcomingTorunaments, setUpcomingTournaments] = useState([]);
  const getUpcomingTournaments = async () => {
    try {
      const response = await publicAPI.get("auth/upcoming");
      setUpcomingTournaments(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getUpcomingTournaments();
  }, []);
  return (
    <>
      <Header />
      <PageTitle title="Tournaments" />
      <div className="px-3">
        <div className="py-3">
          <div className="mt-3 mb-4">
            <h6 className="fw-light">
              {upcomingTorunaments.length} Tournaments found.
            </h6>
          </div>
          <div className="d-flex flex-wrap">
            {upcomingTorunaments.map((tournament) => (
              <div
                className="card hover-card mx-2"
                style={{ width: "18rem" }}
                key={tournament.id}
                onClick={() => {
                  navigate("/view/tournament/" + tournament.id);
                }}
              >
                <img
                  src={"https://api.lvgesports.com/" + tournament.coverPhoto}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h4 className="card-title fw-bold">{tournament.title}</h4>
                  <hr />
                  <div className="row mb-3">
                    <div className="col-6">
                      <span className="fw-light">Country</span>
                    </div>
                    <div className="col-6">
                      <span className="fw-bold text-info">
                        {tournament.country}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6 d-flex flex-column">
                      <span className="fw-light">Organizer</span>
                      <span className="fw-bold text-info">
                        {tournament.organizer}
                      </span>
                    </div>
                    <div className="col-6 d-flex flex-column">
                      <span className="fw-light">Location</span>
                      <span className="fw-bold text-info">
                        {tournament.location}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6 d-flex flex-column">
                      <span className="fw-light">Start Date</span>
                      <span className="fw-bold text-info">
                        {
                          // tournament.startDate to be formatted as yyyy-mm-dd, currently it is like 2024-06-07T00:00:00.000Z
                          tournament.startDate &&
                            tournament.startDate.split("T")[0]
                        }
                      </span>
                    </div>
                    <div className="col-6 d-flex flex-column">
                      <span className="fw-light">End Date</span>
                      <span className="fw-bold text-info">
                        {
                          // tournament.endDate to be formatted as yyyy-mm-dd, currently it is like 2024-06-07T00:00:00.000Z
                          tournament.endDate && tournament.endDate.split("T")[0]
                        }
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div className="row mb-3">
                    <div className="col-6 d-flex flex-column">
                      <span className="fw-light">
                        0 Gamers already registered.
                      </span>
                    </div>
                    <div className="col-6 d-flex flex-column">
                      <span className="fw-light">{tournament.status}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {upcomingTorunaments.length === 0 && (
              <div className="text-center w-100">
                <h5>No tournaments found.</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
