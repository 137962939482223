import { Link, useNavigate, useParams } from "react-router-dom";
import PageTitle from "./components/PageTitle";
import { useEffect, useState } from "react";
import publicAPI from "./config/publicAPI";
import protectedAPI from "./config/protectedAPI";
import Header from "./components/Header";
import Swal from "sweetalert2";

function ViewTournament() {
  const [tournament, setTournament] = useState([]);
  const [tournamentMembers, setTournamentMembers] = useState([]);
  const [tournamentClans, setTournamentClans] = useState([]);
  const [clans, setClans] = useState([]);
  const [selectedClan, setSelectedClan] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const getTournament = async () => {
    try {
      const response = await publicAPI.get("auth/tournament/get/" + id);
      setTournament(response.data);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await publicAPI.get("auth/tournament/members/" + id);
      setTournamentMembers(response.data);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await publicAPI.get("auth/tournament/clans/" + id);
      setTournamentClans(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getOwnClans = async () => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))?.role == 2001
    ) {
      try {
        const response = await protectedAPI.get("clan/getOwnClans");
        setClans(response.data);
      } catch (error) {
        console.error(error);
      }
    } else {
      navigate("/login");
    }
  };
  const registerAsAMember = async (id) => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))?.role == 2001
    ) {
      protectedAPI
        .post("tournament/joinAsMember", { tournamentId: id })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          });
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        });
    } else {
      navigate("/login");
    }
  };
  const registerAsClan = async (id) => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))?.role == 2001
    ) {
      protectedAPI
        .post("tournament/joinAsClan", {
          tournamentId: id,
          clanId: selectedClan,
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
          });
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        });
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    getTournament();
  }, []);
  return (
    <>
      <Header />
      <PageTitle title={tournament.title} />
      <div className="px-3">
        <div className="py-3">
          <ul className="nav nav-underline" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected="true"
              >
                Overview
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="request-tab"
                data-bs-toggle="tab"
                data-bs-target="#request-tab-pane"
                type="button"
                role="tab"
                aria-controls="request-tab-pane"
                aria-selected="true"
              >
                Bracket
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile-tab-pane"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected="false"
              >
                Schedule
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="members-tab"
                data-bs-toggle="tab"
                data-bs-target="#members-tab-pane"
                type="button"
                role="tab"
                aria-controls="members-tab-pane"
                aria-selected="false"
              >
                Registered Players
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="clans-tab"
                data-bs-toggle="tab"
                data-bs-target="#clans-tab-pane"
                type="button"
                role="tab"
                aria-controls="clans-tab-pane"
                aria-selected="false"
              >
                Registered Clans
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active py-4 px-0"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="border-5 border-info border-start p-3 my-4 align-items-center">
                    <h4 className="fw-bold">ABOUT EVENT</h4>
                  </div>
                  <img
                    src={"https://api.lvgesports.com/" + tournament.coverPhoto}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="d-flex flex-column my-4">
                    <h3 className="fw-bold text-info">{tournament.title}</h3>
                    <p className="my-4">{tournament.description}</p>
                  </div>
                </div>
                <div className="col-md-4 ps-5">
                  <div className="border-5 border-info border-start p-3 my-4 align-items-center">
                    <h4 className="fw-bold">EVENT DETAILS</h4>
                  </div>
                  <div className="d-flex flex-column my-4">
                    <h5 className="fw-bold">Date</h5>
                    <p className="mb-4">
                      {
                        // tournament.startDate to be formatted as yyyy-mm-dd, currently it is like 2024-06-07T00:00:00.000Z
                        tournament.startDate &&
                          tournament.startDate.split("T")[0]
                      }
                    </p>
                    <h5 className="fw-bold">Game</h5>
                    <p className="mb-4">{tournament.game}</p>
                    <h5 className="fw-bold">Country</h5>
                    <p className="mb-4">{tournament.country}</p>
                    <h5 className="fw-bold">location</h5>
                    <p className="mb-4">{tournament.location}</p>
                    <h5 className="fw-bold">organizer</h5>
                    <p className="mb-4">{tournament.organizer}</p>
                    <h5 className="fw-bold">Regitration Closing Date</h5>
                    <p className="mb-4">{tournament.regitrationClosingDate}</p>
                    <h5 className="fw-bold">Status</h5>
                    <p className="mb-4">{tournament.status}</p>
                    <div className="d-flex flex-column mt-5">
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          registerAsAMember(tournament.id);
                        }}
                      >
                        Register (As a Solo Player)
                      </button>
                      <button
                        className="btn btn-warning mt-1"
                        data-bs-toggle="modal"
                        data-bs-target="#joinAsClanModal"
                        onClick={getOwnClans}
                      >
                        Register (As a Clan)
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade py-4 px-0"
              id="request-tab-pane"
              role="tabpanel"
              aria-labelledby="request-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-md-12">
                  {tournament.bracket ? (
                    <img
                      src={"https://api.lvgesports.com/" + tournament.bracket}
                      alt=""
                      width={"100%"}
                    />
                  ) : (
                    <>
                      <h3 className="text-center text-secondary">
                        Bracket not available yet.
                      </h3>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade py-3 px-0"
              id="profile-tab-pane"
              role="tabpanel"
              aria-labelledby="profile-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-md-12">
                  {tournament.schedule ? (
                    <img
                      src={"https://api.lvgesports.com/" + tournament.schedule}
                      alt=""
                      width={"100%"}
                    />
                  ) : (
                    <>
                      <h3 className="text-center text-secondary">
                        Schedule not available yet.
                      </h3>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade py-4 px-0"
              id="members-tab-pane"
              role="tabpanel"
              aria-labelledby="members"
              tabindex="0"
            >
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-striped table-hover align-middle">
                    <thead>
                      <tr>
                        <th className="text-secondary" scope="col">
                          #
                        </th>
                        <th className="text-secondary" scope="col">
                          Nickname
                        </th>
                        <th className="text-secondary" scope="col">
                          Country
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tournamentMembers.map((member, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            {member.user.firstname + " " + member.user.lastname}
                          </td>
                          <td>{member.user.nationality}</td>
                        </tr>
                      ))}
                      {tournamentMembers.length == 0 ? (
                        <tr>
                          <td colSpan="3" className="text-center">
                            No members found.
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade py-4 px-0"
              id="clans-tab-pane"
              role="tabpanel"
              aria-labelledby="clans"
              tabindex="0"
            >
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-striped table-hover align-middle">
                    <thead>
                      <tr>
                        <th className="text-secondary" scope="col">
                          #
                        </th>
                        <th className="text-secondary" scope="col">
                          Clan Name
                        </th>
                        <th className="text-secondary" scope="col">
                          Country
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tournamentClans.map((clan, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{clan.clan.name}</td>
                          <td>{clan.clan.country}</td>
                        </tr>
                      ))}
                      {tournamentClans.length == 0 ? (
                        <tr>
                          <td colSpan="3" className="text-center">
                            No clans found.
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="joinAsClanModal"
        tabindex="-1"
        aria-labelledby="joinAsClanModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="joinAsClanModalLabel">
                Register As a Clan
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex flex-column">
                <div className="form-group mb-3">
                  <label htmlFor="clan" className="form-label">
                    Select your clan
                  </label>
                  <select
                    name="clan"
                    id="clan"
                    className="form-control"
                    onChange={(e) => setSelectedClan(e.target.value)}
                  >
                    <option value="">Select</option>
                    {clans.map((clan, index) => (
                      <option key={index} value={clan.id}>
                        {clan.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  registerAsClan(tournament.id);
                }}
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewTournament;
