import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import protectedAPI from "./config/protectedAPI";

function CreateOrganization() {
  const [countries, setContries] = useState([]);
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [tag, setTag] = useState("");
  const getAllCountries = async () => {
    protectedAPI
      .get("utility/getCountryList")
      .then((response) => {
        setContries(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createClan = async () => {
    if (name === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Name is required!",
      });
      return;
    }
    const data = new FormData();
    data.append("name", name);
    data.append("country", country);
    data.append("tag", tag);
    var logo = document.getElementById("clanLogo").files[0];
    data.append("logo", logo);
    protectedAPI
      .post("clan/create", data)
      .then((response) => {
        setName("");
        setCountry("");
        setTag("");
        document.getElementById("clanLogo").value = "";
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };
  useEffect(() => {
    getAllCountries();
  }, []);
  return (
    <>
      <div
        className="modal fade"
        id="createOrganizationModal"
        tabindex="-1"
        aria-labelledby="createOrganizationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="createOrganizationModalLabel"
              >
                CREATE A NEW ORGANIZATION
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex flex-column">
                <div className="form-group mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="country" className="form-label">
                    Country
                  </label>
                  <select
                    name="country"
                    id="country"
                    className="form-control"
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option value="Sri Lanka"></option>
                    {countries.map((country) => (
                      <option key={country.iso} value={country.nicename}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="tag" className="form-label">
                    Description [Optional]
                  </label>
                  <textarea
                    name="tag"
                    id="tag"
                    className="form-control"
                    rows={4}
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="clanLogo" className="form-label">
                    Logo [Optional]
                  </label>
                  <input
                    type="file"
                    name="clanLogo"
                    id="clanLogo"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary" onClick={createClan}>
                Create Team
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateOrganization;
