import { Link } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import { useEffect, useState } from "react";
import protectedAPI from "../config/protectedAPI";
import AdminHeader from "../components/AdminHeader";

function Users() {
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    try {
      const response = await protectedAPI.get("user/getUsers");
      setUsers(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <>
      <AdminHeader />
      <PageTitle title="USER MANAGEMENT" />
      <div className="px-3">
        <div className="py-3">
          <div className="mt-3 mb-4">
            <h6 className="fw-light">{users?.length} Users found.</h6>
          </div>
          <div>
            <table className="table table-striped table-hover align-middle">
              <thead>
                <tr>
                  <th className="text-secondary" scope="col"></th>
                  <th className="text-secondary" scope="col">
                    Name
                  </th>
                  <th className="text-secondary" scope="col">
                    Country
                  </th>
                  <th className="text-secondary" scope="col">
                    Role
                  </th>
                  <th className="text-secondary" scope="col">
                    Email
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index}>
                    <th style={{ width: "10%" }} scope="row">
                      <img
                        src={"https://api.lvgesports.com/" + user.photo}
                        className="clan-row-img"
                        alt="..."
                      />
                    </th>
                    <td>{user.firstname + " " + user.lastname}</td>
                    <td style={{ width: "15%" }}>{user.nationality}</td>
                    <td>{user.role == 2001 ? "User" : "Admin"}</td>
                    <td>{user.email}</td>
                  </tr>
                ))}
                {users.length === 0 && (
                  <tr>
                    <td colSpan="4">No users found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Users;
