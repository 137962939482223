import CreateTeam from "../CreateTeam";
import CreateOrganization from "../CreateOrganization";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Header(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    navigate("/login");
  };
  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))?.role == 2001
    ) {
      setIsLoggedIn(true);
    } else {
      if (
        !window.location.pathname.includes("/view/tournament/") &&
        JSON.parse(localStorage.getItem("user"))?.role == 5150
      ) {
        navigate("/admin/tournaments");
      } else {
        setIsLoggedIn(false);
        if (JSON.parse(localStorage.getItem("user"))?.role == 5150) {
          setIsAdmin(true);
        }
        // if route not equal to / or /clans or /clans/view/:id redirect to /login
        if (
          window.location.pathname !== "/" &&
          window.location.pathname !== "/clans" &&
          !window.location.pathname.includes("/view/clan/") &&
          !window.location.pathname.includes("/view/tournament/")
        ) {
          navigate("/login");
        }
      }
    }
  }, []);
  return (
    <>
      <nav
        className="navbar navbar-expand-lg shadow"
        style={{ backgroundColor: "#3f0c71" }}
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={require("../images/logo.png")} alt="" width={70} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  TOURNAMENTS
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/clans">
                  ORGNIZATIONS & CLANS
                </Link>
              </li>
            </ul>
            {!isAdmin && !isLoggedIn && (
              <>
                <Link className="btn btn-light me-1" to="/register">
                  Register
                </Link>
                <Link className="btn btn-outline-light" to="/login">
                  Login
                </Link>
              </>
            )}
            {isLoggedIn && (
              <>
                <div className="dropdown">
                  <button
                    className="btn btn-default dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    MY PROFILE
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <Link className="dropdown-item" to="/dashboard">
                        DASHBOARD
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/my-teams">
                        MY TEAMS
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/my-clans">
                        MY ORGANIZATIONS
                      </Link>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#createTeamModal"
                      >
                        CREATE TEAM
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#createOrganizationModal"
                      >
                        CREATE ORGANIZARION
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => logout()}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </nav>
      <CreateTeam />
      <CreateOrganization />
    </>
  );
}

export default Header;
