import axios from "axios";

const protectedAPI = axios.create({
  baseURL: `https://api.lvgesports.com/api/v1/`,
});

// Add a request interceptor
protectedAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token refresh
protectedAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem("refresh_token");
        if (refreshToken) {
          const response = await axios.post(
            "https://api.lvgesports.com/api/v1/auth/refresh",
            {
              refreshToken: refreshToken,
            }
          );

          const newAccessToken = response.data.accessToken;
          localStorage.setItem("access_token", newAccessToken);

          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${newAccessToken}`;
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

          return protectedAPI(originalRequest);
        }
      } catch (err) {
        console.error("Refresh token expired or invalid", err);
        // Handle token refresh failure (e.g., redirect to login)
        alert(
          "Your session has expired. Please log in again. >>>" + err.message
        );
        window.location.href = "/login";
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

// // an axios interceptor that adds the token to the request headers
// protectedAPI.interceptors.request.use((config) => {
//   const token = localStorage.getItem("access_token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   } else {
//     delete config.headers.Authorization;
//     refreshAccessToken().then(() => {
//       config.headers.Authorization = `Bearer ${localStorage.getItem(
//         "access_token"
//       )}`;
//     });
//   }
//   return config;
// });

// // response interceptor to refresh token
// protectedAPI.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 403) {
//       refreshAccessToken();
//     }
//     return Promise.reject(error);
//   }
// );

// const refreshAccessToken = async () => {
//   const refreshToken = localStorage.getItem("refresh_token");
//   if (refreshToken) {
//     axios
//       .post("https://api.lvgesports.com/api/v1/auth/refresh", {
//         refreshToken: refreshToken,
//       })
//       .then((response) => {
//         localStorage.setItem("access_token", response.access_token);
//         return;
//       });
//   } else {
//     window.location.href = "/login";
//   }
// };

export default protectedAPI;
