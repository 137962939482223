import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function AdminHeader(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    navigate("/login");
  };
  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user"))?.role == 5150
    ) {
      setIsLoggedIn(true);
    } else {
      logout();
    }
  }, []);
  return (
    <>
      <nav
        className="navbar navbar-expand-lg shadow"
        style={{ backgroundColor: "#3f0c71" }}
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/admin/tournaments">
            <img src={require("../images/logo.png")} alt="" width={70} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="/admin/tournaments">
                  Tournaments Management
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/clans">
                  Clans Management
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/admin/teams">
                  Teams Management
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/admin/users">
                  User Management
                </Link>
              </li>
            </ul>
            <div className="dropdown">
              <button
                className="btn btn-default dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Admin
              </button>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => logout()}
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default AdminHeader;
