import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import publicAPI from "./config/publicAPI";

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "All fields are required!",
      });
      return;
    }
    const data = {
      email: email,
      password: password,
    };
    publicAPI
      .post("auth/login", data)
      .then((response) => {
        document.getElementById("closeLoginModalButton").click();
        setEmail("");
        setPassword("");
        localStorage.setItem("access_token", response.data.accessToken);
        localStorage.setItem("refresh_token", response.data.refreshToken);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        }).then((isConfirmed) => {
          if (isConfirmed) {
            if (response.data.user.role === 5150) {
              navigate("/admin/teams");
            } else {
              navigate("/dashboard");
            }
          }
        });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        }
      });
  };

  useEffect(() => {
    // click the button to open the modal
    document.getElementById("loginModalButton").click();
  }, []);
  return (
    <section
      class="contact-page-area section-gap mt-0 pt-5"
      style={{ height: "100vh" }}
    >
      <button
        id="loginModalButton"
        className="btn d-none"
        data-bs-toggle="modal"
        data-bs-target="#loginModal"
      ></button>
      <div
        className="modal fade"
        id="loginModal"
        tabindex="-1"
        aria-labelledby="loginModalLabel"
        aria-hidden="true"
        // data-bs-backdrop="static"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ width: "400px" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <form id="login_form">
                <div className="d-flex flex-column">
                  <div class="section-title-wrap text-center">
                    <img
                      src={require("./images/logo.png")}
                      alt=""
                      width={200}
                    />
                    <p>First thing is first. Login to your account.</p>
                  </div>
                  <hr />
                  <div className="d-flex flex-column">
                    <div className="form-group mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group my-3">
                    <button className="btn btn-primary w-100" onClick={login}>
                      Sign Me In
                    </button>
                    <button
                      id="closeLoginModalButton"
                      type="button"
                      className="btn btn-default d-none"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                  <div className="form-group">
                    <Link to="/register" className="btn btn-default w-100">
                      Don't have an account? Register
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
